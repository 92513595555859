$space: 0.25rem;

// Here you can add other styles
// img, svg {
//     vertical-align: top !important;
// }

//In icons of buttons is in bottom no render success
.ant-btn .anticon {
  vertical-align: unset;
}

.ant-steps-icon .anticon {
  vertical-align: unset !important;
}

.swal2-styled.swal2-confirm {
  background-color: #1890ff !important;
}

// message alert
.ant-message {
  z-index: 2003 !important;
}

.ant-image-preview-mask,
.ant-image-preview-wrap {
  z-index: 2003 !important;
}

.tappit-object-fit-cover {
  object-fit: cover;
}

.tappit-circular {
  border-radius: 50%;
}


//**** utility

.font-weight-bold {
  font-weight: bold;
}

.bg-soft-success {
  background-color: #C3E6CB;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-30px {
  margin-top: 30px;
}

.mt-29px {
  margin-top: 29px;
}

.mt-28px {
  margin-top: 28px;
}

.mt-25px {
  margin-top: 25px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.5) !important;
}

.break-ant-label .ant-form-item-label>label {
  height: auto;
}

// ******* for translation design

.generic-container-translations {

  .square-item-translation-container {

    .square-item-translation {

      display: inline-block;
      width: 70%;
      height: 55%;
      // border:none;
      border-bottom: dashed thin grey;
      position: absolute;
      margin-left: 15px;

    }

    .square-item-translation-vl {
      height: 100%;
      width: 1px;
      display: inline-block;
      margin-left: 15px;
      border-left: dashed thin grey;

      &.last-item {
        height: 55%;
      }

    }

  }

}